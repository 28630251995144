import { AsyncPipe, CurrencyPipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  inject,
} from '@angular/core';
import { FormsModule } from '@angular/forms';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import {
  faDollarSign,
  faMoneyBillTransfer,
} from '@fortawesome/pro-regular-svg-icons';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { PayoutMerged } from 'src/app/shared/models';
import { SortAlphabeticallyPipe } from 'src/app/shared/services/account/pipes/sort-alphabetically.pipe';
import { AccountActions } from 'src/app/shared/store/account/+state/account.actions';
import { AccountState } from 'src/app/shared/store/account/+state/account.reducer';
import { selectAccountsByOwnerIds } from 'src/app/shared/store/account/+state/account.selectors';

@Component({
  selector: 'app-payout-cycle-amount-change-edit',
  standalone: true,
  imports: [
    AsyncPipe,
    CurrencyPipe,
    FaIconComponent,
    FormsModule,
    SortAlphabeticallyPipe,
  ],
  templateUrl: './payout-cycle-amount-change-edit.component.html',
  styleUrl: './payout-cycle-amount-change-edit.component.scss',
})
export class PayoutCycleAmountChangeEditComponent implements OnInit {
  private cdr = inject(ChangeDetectorRef);
  private store = inject(Store);

  faDollarSign = faDollarSign;
  faMoneyBillTransfer = faMoneyBillTransfer;

  @Input() amountChanges?: PayoutMerged;
  @Input() payoutsMerged?: PayoutMerged;
  @Input() agencyId?: string;

  @Output() valueChanged = new EventEmitter<PayoutMerged>();

  accounts$?: Observable<AccountState>;

  ngOnInit() {
    this.getAccounts();
  }

  getAccounts(): void {
    if (this.agencyId && this.payoutsMerged?.id) {
      this.accounts$ = this.store.select(
        selectAccountsByOwnerIds([this.agencyId, this.payoutsMerged?.id]),
      );
      this.cdr.detectChanges();

      this.store.dispatch(
        AccountActions.loadAccounts({
          agencyId: this.payoutsMerged.id,
          userType: this.payoutsMerged.userType,
        }),
      );
    } else {
      console.error('No agencyId or payoutsMerged', {
        agencyId: this.agencyId,
        payoutsMerged: this.payoutsMerged,
      });
    }
  }

  updateAmountChange(accountId: string, amount: number) {
    console.log('updateAmountChange', accountId, amount);

    if (this.amountChanges) {
      if (!this.amountChanges.accountBalance) {
        this.amountChanges.accountBalance = {
          balance: 0,
          byAccount: {},
        };
      }

      if (!this.amountChanges.accountBalance.byAccount) {
        this.amountChanges.accountBalance.byAccount = {};
      }

      if (!this.amountChanges.accountBalance.byAccount[accountId]) {
        this.amountChanges.accountBalance.byAccount[accountId] = {
          balance: 0,
        };
      }

      this.amountChanges.accountBalance.byAccount[accountId].balance = amount;
      this.recalculateTotal();
    }

    this.valueChanged.emit(this.amountChanges);
  }

  recalculateTotal() {
    console.log('recalculateTotal');

    if (this.amountChanges) {
      let total = 0;

      if (!this.amountChanges.accountBalance) {
        this.amountChanges.accountBalance = {
          balance: 0,
          byAccount: {},
        };
      }

      for (const accountId in this.amountChanges.accountBalance.byAccount) {
        total -=
          this.amountChanges.accountBalance.byAccount[accountId].balance ?? 0;
      }

      if (this.amountChanges.payout) {
        this.amountChanges.payout.total = total;
        this.amountChanges.payout.personal = total;
      }
    }
  }
}
